.connected.loading {
    display: inline;
}
.loading {
    height: 35px;
    fill: white;
    display: none;
}
.line {
    display: block;
    white-space: pre-wrap;
    font-size: 0.675rem;
    line-height: 0.9rem;
}
